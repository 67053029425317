import { getAviBookingDetails } from '../services/aviBookingService'
import getOktaConfigs from './oktaConfigs'
/*
 * services denotes service name or predicate path given in the gateway
 * with spring cloud gateway predicate.path is used.
 * for more details check the property file of cloud-gateway-service.
 * if changing to other api gateway like zzul or aws api gateway service name can be used.
 */
const env = process.env.REACT_APP_ENV || 'local'

const services = {
	masterDataService: '/masterdata',
	listFlightService: '/listflight',
	bookingService: '/booking',
	bookingDetails: '/bookingdetails',
	userService: '/user',
	authService: '/user/auth',
	notificationService: '/notification',
	csnService: 'services'
}
const urls = {
	getQuoteSearchResultsUrl: `${services.listFlightService}/listFlightInformation`,
	getMenuResponseUrlen: `${process.env.PUBLIC_URL}/media/${env}/menu-response.json`,
	getMenuResponseUrlzh: `${process.env.PUBLIC_URL}/media/${env}/menu-response-zh.json`,
	// getOnLoadUrl: '/getloadingdetails',
	// getOriginsUrl: '/origins',
	// getDestinationsUrl: '/destinations',
	getLoginUrl: `/loginurl`,
	getAirportsUrl: `${services.masterDataService}/airports`,
	getAirportByCodeUrl: `${services.masterDataService}/airportbycode`,
	getConfirmationEnabledFlagUrl: `${services.masterDataService}/getconfirmationenabledflag`,
	getDisclaimerEnabledFlagUrl: `${services.masterDataService}/getdisclaimerenabledflag`,
	getShippingItemsUrl: `${services.masterDataService}/shippingitems`,
	getSccUrl: `${services.masterDataService}/scc`,
	getAdditionalSccsUrl: `${services.masterDataService}/additionalscc`,
	getServicesUrl: `${services.masterDataService}/service`,
	getULDTypeByCodeUrl: `${services.masterDataService}/uldtypebycode`,
	getULDTypesUrl: `${services.masterDataService}/uldtypes`,
	getPivotWeightUrl: `${services.masterDataService}/pivotweightinfo`,
	getProductsUrl: `${services.masterDataService}/booking/products`,
	getProductBySystemNameUrl: `${services.masterDataService}/productbysystemname`,
	checkSccCompatibilityurl: `${services.masterDataService}/checkSccCompatibility`,
	getStationDetailsUrl: `${services.masterDataService}/station`,
	getCountriesUrl: `${services.masterDataService}/countries`,
	getCountryByCodeUrl: `${services.masterDataService}/countrybycode`,
	getCountryByNameUrl: `${services.masterDataService}/countrybyname`,
	getChargeHeads: `${services.masterDataService}/chargehead`,
	getCurrencyListUrl: `${services.masterDataService}/currency`,
	getNavigationMenuUrl: `${services.masterDataService}/menu-response`,

	getFlightEnquiryUrl: `${services.listFlightService}/flightEnquiry`,
	getValidatePromoCodeUrl: `${services.listFlightService}/validatepromocode`,
	getValidateSpotRateUrl: `${services.listFlightService}/validatespotrate`,
	checkBigOrOHGUrl: `${services.listFlightService}/check-big-ohg`,

	getUserListUrl: `${services.userService}/userdetails/listusers`,
	updatedUserStatusUrl: `${services.userService}/userdetails/changeuserstatus`,
	createUserUrl: `${services.userService}/userdetails/createuser`,
	updateUserUrl: `${services.userService}/userdetails/updateuser`,
	getUserDetailsUrl: `${services.userService}/userdetails/getuserdetials`,
	getUserConfig: `${services.userService}/userdetails/permanentbookingflag`,
	getUserIdByAgentCode: `${services.userService}/userdetails/useridbyagentcode`,
	getRegionByAirportCode: `${services.masterDataService}/getregionbyairportcode`,

	//auth  urls
	getTokenUrl: `${services.authService}/token`,
	validateSessionUrl: `${services.authService}/validate`,
	refreshTokenUrl: `${services.authService}/refresh`,
	getSessionLogoutUrl: `${services.authService}/logout`,

	sendEmailUrl: `${services.notificationService}/send-email`,
	createPrintUrl: `${services.notificationService}/create-print`,

	saveBookingUrl: `${services.bookingService}/bookingdetails/savebookingdetails`,
	updateBookingUrl: `${services.bookingService}/bookingdetails/updatebookingdetails`,
	cancelBookingUrl: `${services.bookingService}/bookingdetails/cancelbooking`,
	validateBookingUrl: `${services.bookingService}/bookingdetails/validatebooking`,
	saveQuoteUrl: `${services.bookingService}/bookingdetails/savequote`,
	findQuoteUrl: `${services.bookingService}/bookingdetails/findquote`,
	deleteQuoteUrl: `${services.bookingService}/bookingdetails/deletequote`,
	listQuoteUrl: `${services.bookingService}/bookingdetails/listquotes`,
	getBookingListUrl: `${services.bookingService}/bookingdetails/findbookings`,


	getTemplateListUrl: `${services.bookingService}/bookingdetails/listtemplates`,
	findTemplatetUrl: `${services.bookingService}/bookingdetails/findtemplates`,
	updateTemplatetUrl: `${services.bookingService}/bookingdetails/updatetemplate`,
	deleteTemplatetUrl: `${services.bookingService}/bookingdetails/deletetemplate`,
	getBookingDetailsUrl: `${services.bookingService}/bookingdetails/getbookingdetails`,
	getAdditionaFormInfoUrl: `${services.bookingService}/bookingdetails/getAdditionaFormInfo`,
	getAWBDetailsUrl: `${services.bookingService}/bookingdetails/getawbdetails`,
	saveAWBDetailsUrl: `${services.bookingService}/bookingdetails/saveawbdetails`,
	printAwbDetailsUrl: `${services.bookingService}/bookingdetails/printawbdetails`,
	saveTemplateUrl: `${services.bookingService}/bookingdetails/savetemplate`,
	updateTemplateUrl: `${services.bookingService}/bookingdetails/updatetemplate`,
	saveDraftUrl: `${services.bookingService}/bookingdetails/savedraft`,
	listDraftUrl: `${services.bookingService}/bookingdetails/listdrafts`,
	getDraftsDetailsUrl: `${services.bookingService}/bookingdetails/finddraft`,
	deleteDraftsUrl: `${services.bookingService}/bookingdetails/deletedraft`,
	saveDataCaptureTnCUrl: `${services.userService}/userdetails/accept-datacapture-tnc`,
	findHAWBDetailsUrl: `${services.bookingService}/bookingdetails/findhawbdetails`,
	saveHAWBDetailsUrl: `${services.bookingService}/bookingdetails/savehawbdetails`,
	deleteHAWBDetailsUrl: `${services.bookingService}/bookingdetails/deletehawbdetails`,
	getAllotmentsUrl: `${services.bookingService}/allotments/listallotments`,
	getRevenueDataUrl: `${services.bookingService}/dashboard/get-revenue-data`,

	saveCommodityUrl: `${services.masterDataService}/savecommoditymasterlist`,
	saveAirportMasterUrl: `${services.masterDataService}/saveairportmasterlist`,
	saveServiceMasterUrl: `${services.masterDataService}/saveserviceaslist`,
	saveEmbargoMasterUrl: `${services.masterDataService}/saveembargorestrictionmasterlist`,
	saveProductMasterUrl: `${services.masterDataService}/saveproductmasterlist`,
	saveSccMasterUrl: `${services.masterDataService}/savesccaslist`,
	listAirportsMasterUrl: `${services.masterDataService}/airports`,
	listServiceMasterUrl: `${services.masterDataService}/admin/service`,
	revenueDashboardUrl: `${services.bookingService}/dashboard/get-revenue-data`,
	getProductsMasterUrl: `${services.masterDataService}/getproductlist`,
	getAirportsMasterUrl: `${services.masterDataService}/getairportlist`,
	getServicesMasterUrl: `${services.masterDataService}/getservicelist`,
	getCommodityMasterUrl: `${services.masterDataService}/getcommoditylist`,
	getEmbargoMasterUrl: `${services.masterDataService}/getembargorestrictionlist`,
	getSccMasterUrl: `${services.masterDataService}/getscclist`,
	getTermsConditions: `${services.masterDataService}/listtermsandconditions`,
	saveTermsConditions: `${services.masterDataService}/savetermsandconditions`,
	termsAndConditionByLocation: `${services.masterDataService}/termsandconditionsbylocationcode`,
	getUldTypeMaster: `${services.masterDataService}/getuldlist`,
	getPivotInfoMaster: `${services.masterDataService}/getPivotInfolist`,
	saveUldTypeMaster: `${services.masterDataService}/saveUldmasterlist`,
	savePivotInfoMaster: `${services.masterDataService}/savePivotInfomasterlist`,
	getSccIncompatibilityMaster: `${services.masterDataService}/getSccIncompaitilityInfolist`,
	saveSccIncompatibilityMaster: `${services.masterDataService}/saveSccIncompaitilitymasterlist`,
	getNewsFeedUrl: `${services.masterDataService}/news-feed-response`,
	getStationScheduleMaster: `${services.masterDataService}/getstationshedulemasterlist`,
	saveStationScheduleMaster: `${services.masterDataService}/savestationshedulemasterlist`,

	//permentbooking
	// getPermanentBookingFileList: `${services.bookingService}/permanentbooking/listfiles`,
	getPermanentBookingFileList: `${services.bookingService}/bookingdetails/listmassbookings`,
	//getPermanentBookingsForFile: `${services.bookingService}/permanentbooking/bookinglistforfile`,
	getPermanentBookingsForFile: `${services.bookingService}/bookingdetails/findmassbookings`,
	registerPermanentbooking: `${services.bookingService}/permanentbooking/registerpermanentbooking`,
	reprocessPermanentbooking: `${services.bookingService}/permanentbooking/reprocesspermanentbooking`,

	saveRegionMaster: `${services.masterDataService}/saveregionlist`,
	getRegionMaster: `${services.masterDataService}/getregionlist`,

	saveCountryMaster: `${services.masterDataService}/savecountrylist`,
	getCountryMaster: `${services.masterDataService}/getcountrylist`,

	// schedule-visualization
	processFlightRotations: `${services.bookingService}/schedule/process-flight-rotations`,
	swapInboundFlights: `${services.bookingService}/schedule/swap-inbound-flights`,
	processSchedules: `${services.bookingService}/schedule/process-schedules`,
	getFlightRotations: `${services.bookingService}/schedule/round-trips`,
	getSegments: `${services.bookingService}/schedule/segments`,
	updateSegments: `${services.bookingService}/schedule/update-segments`,
	exportPdf: `${services.bookingService}/schedule/generate-pdf`,
	//seasonalbooking
	saveSeasonalBooking: `${services.bookingService}/permanentbooking/registerpermanentbookingbytemplate`,
	getSeasonalBookingList: `${services.bookingService}/bookingdetails/listtemplatebookings`,

	//csn dashboard
	flightList: `${services.csnService}/flightlist`,
	awbList: `${services.csnService}/flightawblist`,
	placiUserDetails: `${services.csnService}/userDetails`,
	ffmDetails: `${services.csnService}/flight-ffm-details`,
	placiAirportsMaster: `${services.csnService}/airports-master`,

	//avi booking
	getAviBookingDetails: `${services.bookingService}/bookingdetails/getfeaturedetails`,
	getAviBookingBreeds: `${services.masterDataService}/getbreedbyliveanimals`,
	postAviPetAttachment: `${services.bookingService}/attachment/saveAttachment`,
	deleteAviPetAttachment: `${services.bookingService}/attachment/deleteAttachment`,


	// Dg Booking

	getUnidInfo: `${services.masterDataService}/getunidlist`,
	savespecialcommodityattachment: `${services.bookingService}/attachment/savespecializedcommodityattachment`,
	validateunid: `${services.bookingService}/bookingdetails/validateunid`,
	deletespecialcommodityattachment: `${services.bookingService}/attachment/deletespecialcommodityattachment`,
	//reports
	generateAwbReport: `${services.bookingService}/reports/generateawbdetailsreport`,
	generateDapReport: `${services.bookingService}/deliveredPlannedReport/generateReport`,
}
const dev = {
	...urls,
	env,
	baseUrl: 'http://13.126.80.20/api/api',
	csnUrl: 'https://ppe-booking.etihadcargo.com/',
	oktaConfigs: getOktaConfigs('dev'),
	bookingPortalAppUrl: 'http://13.126.80.20',
	checkSessionInterval: 15,
	getGeoLocationUrl: 'https://ppe-booking.etihadcargo.com/edge-services/countrycode',
	parentUrl: 'https://dev.etihadcargo.com',
	MULTILANGUAGE_SUPPORT_ENABLED: true,
	UNKNOWN_SHIPPER_ENABLED: true,
	MASS_BOOKING_ENABLED: true,
	ENABLE_ANALYTICS: true,
	DASHBOARD_ALLOTMENT_VIEW_ENABLED: true
}

const local = {
	...urls,
	env,
	//baseUrl: 'http://localhost:3001',
	//baseUrl: 'http://localhost:8661',
	//baseUrl: 'http://localhost:8080/api',
	baseUrl: 'http://13.126.80.20/api/api',
	//baseUrl: 'https://ppe-booking.etihadcargo.com/api',
	//baseUrl: 'http://13.126.80.20:80',
	//oktaConfigs: getOktaConfigs('local'),
	csnUrl: 'https://ppe-booking.etihadcargo.com/',
	//csnUrl: 'http://localhost:8210',
	oktaConfigs: getOktaConfigs('dev'),
	bookingPortalAppUrl: 'https://ppe-booking.etihadcargo.com',
	getGeoLocationUrl: 'https://ppe-booking.etihadcargo.com/edge-services/countrycode',
	//appLoginUrl: 'http://localhost:3000/login',
	checkSessionInterval: 15,
	parentUrl: 'https://dev.etihadcargo.com',
	cosumerOktaSsoUrl:
		'https://eappp.okta-emea.com/app/etihadaviationgroup_eagetihadcargowebsitetest_1/exk1mbmzp7HPwEnmM0i7/sso/saml',
	enterpriseOktaSsoUrl:
		'https://eappp.okta-emea.com/app/etihadaviationgroup_eagetihadcargowebsitetest_1/exk1mbmzp7HPwEnmM0i7/sso/saml',
	MULTILANGUAGE_SUPPORT_ENABLED: true,
	UNKNOWN_SHIPPER_ENABLED: true,
	MASS_BOOKING_ENABLED: true,
	ENABLE_ANALYTICS: true,
	DASHBOARD_ALLOTMENT_VIEW_ENABLED: true,
}

const test = {
	...urls,
	env,
	baseUrl: 'http://10.246.28.120:8080/api',
	csnUrl: 'http://10.246.28.120:9002/',
	oktaConfigs: getOktaConfigs('test'),
	bookingPortalAppUrl: 'https://ppe-booking.etihadcargo.com',
	getGeoLocationUrl: 'https://ppe-booking.etihadcargo.com/edge-services/countrycode',
	//appLoginUrl: 'http://10.246.28.120/login',
	checkSessionInterval: 15,
	parentUrl: 'https://test.etihadcargo.com',
	cosumerOktaSsoUrl:
		'https://eappp.okta-emea.com/app/etihadaviationgroup_eagetihadcargowebsitetest_1/exk1mbmzp7HPwEnmM0i7/sso/saml',
	enterpriseOktaSsoUrl:
		'https://etihad.oktapreview.com/app/etihad_eagetihadcargoaemtest_1/exk9kzgqdfE0wBFbC0h7/sso/saml',
	MULTILANGUAGE_SUPPORT_ENABLED: true,
	UNKNOWN_SHIPPER_ENABLED: true,
	MASS_BOOKING_ENABLED: true,
	ENABLE_ANALYTICS: true,
	DASHBOARD_ALLOTMENT_VIEW_ENABLED: true,
}

const stage = {
	...urls,
	env,
	baseUrl: 'https://ppe-booking.etihadcargo.com/api',
	csnUrl: 'https://ppe-booking.etihadcargo.com/',
	oktaConfigs: getOktaConfigs('stage'),
	bookingPortalAppUrl: 'https://ppe-booking.etihadcargo.com',
	getGeoLocationUrl: 'https://ppe-booking.etihadcargo.com/edge-services/countrycode',
	appLoginUrl: 'https://test.etihadcargo.com/en/login?lt=consumer&a=login',
	checkSessionInterval: 15,
	logOutUrl:
		'https://test.etihadcargo.com/services/EtihadCargoUserAccessManager/private/v1/logout',
	parentUrl: 'https://test.etihadcargo.com',
	cosumerOktaSsoUrl:
		'https://eappp.okta-emea.com/app/etihadaviationgroup_eagetihadcargowebsitetest_1/exk1mbmzp7HPwEnmM0i7/sso/saml',
	enterpriseOktaSsoUrl:
		'https://etihad.oktapreview.com/app/etihad_eagetihadcargoaemtest_1/exk9kzgqdfE0wBFbC0h7/sso/saml',
	MULTILANGUAGE_SUPPORT_ENABLED: true,
	UNKNOWN_SHIPPER_ENABLED: true,
	MASS_BOOKING_ENABLED: true,
	ENABLE_ANALYTICS: true,
	DASHBOARD_ALLOTMENT_VIEW_ENABLED: true,
}

const k8s = {
	...urls,
	env,
	baseUrl: 'http://internal-k8s-eycargop-eycargop-7a4a096777-1882133808.ap-south-1.elb.amazonaws.com/api',
	csnUrl: 'https://ppe-booking.etihadcargo.com/',
	oktaConfigs: getOktaConfigs('k8s'),
	bookingPortalAppUrl: 'http://internal-k8s-eycargop-eycargop-7a4a096777-1882133808.ap-south-1.elb.amazonaws.com',
	getGeoLocationUrl: 'https://ppe-booking.etihadcargo.com/edge-services/countrycode',
	parentUrl: 'https://dev.etihadcargo.com'
}

const prod = {
	...urls,
	env,
	baseUrl: 'https://booking.etihadcargo.com/api',
	csnUrl: 'https://booking.etihadcargo.com/',
	bookingPortalAppUrl: 'https://booking.etihadcargo.com',
	getGeoLocationUrl: 'https://booking.etihadcargo.com/edge-services/countrycode',
	appLoginUrl: 'https://etihadcargo.com/en/login?lt=consumer&a=login',
	checkSessionInterval: 15,
	logOutUrl:
		'https://etihadcargo.com/services/EtihadCargoUserAccessManager/private/v1/logout',
	parentUrl: 'https://etihadcargo.com',
	cosumerOktaSsoUrl:
		'https://eap.okta-emea.com/app/etihadglc_eagetihadcargoaemprod_1/exk18l6vfdUaliqp40i7/sso/saml',
	enterpriseOktaSsoUrl:
		'https://etihad.okta.com/app/etihadairways_eagetihadcargoaemprod_1/exkgiumdppbByJuOP0x7/sso/saml',
	MULTILANGUAGE_SUPPORT_ENABLED: true,
	UNKNOWN_SHIPPER_ENABLED: true,
	MASS_BOOKING_ENABLED: true,
	ENABLE_ANALYTICS: true,
	DASHBOARD_ALLOTMENT_VIEW_ENABLED: true,
}

const config = {
	local,
	dev,
	test,
	k8s,
	stage,
	prod,
}

export default (function () {
	return config[env]
})()