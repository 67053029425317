import React, { useEffect,useRef } from 'react'
import FilterOptions from '../FilterOptions'
import FlightDetails from '../FlightDetails'
import './flight-details-list.scss'
import MESSAGES from '../../constants/Messages'
import { useTranslation } from 'react-i18next'
import { addError,pushEventInstantConfirmation } from '../../utils/analytics'
const FlightDetailsList = ({
	setEnquiredPresent,
	airportList,
	selectedDate,
	response,
	setInstantConfimation,
}) => {

	const { t, i18n } = useTranslation(['labels','errors'])
	const prevAirportList = usePrevious(airportList);

	function usePrevious(value) {
		const ref = useRef();
		useEffect(() => {
		  ref.current = value;
		}, [value]);
		return ref.current;
	  }

	/*useEffect(() => {
		console.log('airportList', airportList, prevAirportList);
		if(JSON.stringify(airportList) !== JSON.stringify(prevAirportList)){
		if(airportList.length > 0 && response && response.isLoading === false){
			let airportsFilteredByValidTotalRate = airportList.filter((airport => airport.ratingDetails[0].totalRate !== 0))
			if(airportsFilteredByValidTotalRate.length === 0){
				pushEventInstantConfirmation('No');
			}
			else{
				let airportsFilteredByNoInstantData = airportList.filter((airport) => 
				airport.overBookingAllowed !== false || airport.overHangShipment !== false 
				|| airport.bigShipment !== false || airport.isForceQueued !== false)
				if(airportsFilteredByNoInstantData.length !== 0){
					pushEventInstantConfirmation('No');
				}
				else{
					pushEventInstantConfirmation('Yes');
				}
			}
		}
		else if(airportList.length === 0 && response && response.isLoading === false){
			console.log('pushEventInstantConfirmation - No');
			pushEventInstantConfirmation('No');
		}
	}
	},[airportList])*/

	const getFlightDetails = (flightSelected) => {
		let selectedFlight = flightSelected
		const totalSurcharge = calculateTotalSurcharge(selectedFlight)
		if (totalSurcharge !== 0) {
			selectedFlight.ratingDetails[0].totalSurcharge = totalSurcharge
		}
		return selectedFlight
	}

	const calculateTotalSurcharge = (flightSelected) => {
		let totalSurcharge = 0.0
		if (
			flightSelected.ratingDetails &&
			flightSelected.ratingDetails.length > 0 &&
			flightSelected.ratingDetails[0].otherChargeDetails &&
			flightSelected.ratingDetails[0].otherChargeDetails.length > 0
		) {
			flightSelected.ratingDetails[0].otherChargeDetails.forEach(function (
				otherCharge
			) {
				if (otherCharge.chargeAmount) {
					totalSurcharge = totalSurcharge + otherCharge.chargeAmount
				}
			})
		}
		return totalSurcharge
	}

	return (
		<>
			<div className='flight-details col-12 mb-2'>
				{/* <div className='fd-header'> */}
					{/* <div className='fd-header__left'> */}
					{/* <div className='fd-header__results'>{`${
						airportList && airportList.length ? airportList.length : 0
					}`}</div>
					&nbsp;
					<div className='fd-header__results'>{t('CHOOSE_FLT.NO_OF_FLTS')}</div> */}
					{/* <div className='flights_count'>
						<div className='fd-header__results'>{`${
							airportList && airportList.length ? airportList.length : 0
						}`}</div>
						&nbsp;
						<div className='fd-header__results'>{`Flights`}</div>
					</div> */}
					{/* <div> */}
				<FilterOptions airportList={airportList} selectedDate={selectedDate} />
					{/* </div> */}
				{/* </div> */}
				{/*<div className="fd-header__right">
					<a className='l-link'>
						<span>Clear Selection</span>
					</a>
				</div>*/}
			</div>
			<div className='flight-details__list col-12'>
				{airportList && airportList.length ? (
					airportList.map((destinationAirport, index) => (
						<FlightDetails
							setEnquiredPresent={setEnquiredPresent}
							destinationAirport={destinationAirport}
							key={index}
						/>
					))
				) : (
					<></>
				)}
			</div>
			{/* </div> */}
			{response && airportList && airportList.length === 0 ? (
				<div className='col-12'>
					<div className='no-data'>
						{response && response.isLoading
							? t(MESSAGES.SEARCHING, { ns: 'errors' })
							: response.error
							? (t(response.error.description, { ns: 'errors' }))
							: (t(MESSAGES.NO_FLIGHTS, { ns: 'errors' }))}
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	)
}

export default FlightDetailsList
