import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import fastTrack from '../../assets/images/fasttrack.png'
import downArrow from '../../assets/images/down-arrow.png'
import upArrow from '../../assets/images/up-arrow.png'
import warning_filled from '../../assets/images/warning_filled.svg'
import flightIcon from '../../assets/images/cargo_flight.svg'
import siteLogo from '../../assets/images/logo.png'
import minusIcon from '../../assets/images/minus.svg'
import plusIcon from '../../assets/images/plus.svg'
import truckIcon from '../../assets/images/cargo_truck.svg'
import {
	getDecimalFormattedNumber,
	getRoundedValue, getWeightUnit,
	SERVICES, WEIGHT_UNIT
} from '../../constants'
import { RATE_TYPES } from '../../constants/rateTypes'
import {
	clearItemFromSessionStorage,
	getBase64Decoded,
	getBase64Encoded,
	getFromSessionStorage,
	setToSessionStorage
} from '../../utils/StorageUtils'
import { useTranslation } from 'react-i18next'
const FlightDetails = ({
	setEnquiredPresent,
	destinationAirport,
	searchFormData,
	filters,
	setSearchFormDataToState,
	setFiltersToState,
	showInfoPopup,
	closeInfoPopup
}) => {
	const [isExpanded, setExpanded] = useState(false)
	const encodedflightParameterObj = getFromSessionStorage('flightParameterObj');
	const flightParameterObj = encodedflightParameterObj ? getBase64Decoded(encodedflightParameterObj) : {}


	const { t, i18n } = useTranslation(['labels', 'errors'])

	useEffect(() => {
		setExpanded(false)
	}, [destinationAirport])

	const departureTime = moment(destinationAirport.departureDateTime)
	const departureDate = moment(destinationAirport.departureDateTime, 'DD-MMM-YYYY')
	const flightDate = searchFormData?.shippingDate
	const arrivalTime = moment(destinationAirport.arrivalDateTime)
	const arrivalDate = moment(destinationAirport.arrivalDateTime, 'DD-MMM-YYYY')
	const origin = destinationAirport.origin
	const destination = destinationAirport.destination
	let routes = destinationAirport.flightSegmentList
		? destinationAirport.flightSegmentList.length - 1
		: 0
	const isHideRate = (destinationAirport.isForceQueued ||
		destinationAirport.overHangShipment ||
		destinationAirport.bigShipment)
		? true
		: false

	const isNoInstantBooking =
		destinationAirport.warningMessages &&
		destinationAirport.warningMessages.length

	const history = useHistory()
	const setFlight = (flightSelected) => {
		let encoded = getBase64Encoded(flightSelected)
		setToSessionStorage('flightSelected', encoded)
		history.push('/booking/summary')
	}

	const getFormatedTime = (value) => {
		let formattedTime = ''
		if (value) {
			let tempArray = value.split(':')
			formattedTime =
				parseInt(tempArray[0], 10) +
				'Hrs ' +
				(tempArray[1] && tempArray[1] != '00' ? tempArray[1] + ' Min' : '')
		}
		return formattedTime
	}


	let flightNumberValCombination = destinationAirport?.flightSegmentList?.map(segment => {
		return segment?.flightNumber
	})

	let flightDateValCombination = destinationAirport?.flightSegmentList?.map(segment => {
		return segment?.flightDate
	})


	useEffect(() => {
		if (JSON.stringify(flightParameterObj?.flightNumberVal) === JSON.stringify(flightNumberValCombination) &&
			JSON.stringify(flightParameterObj?.flightDateVal) === JSON.stringify(flightDateValCombination)) {
			setExpanded(true);
			setEnquiredPresent(true);
			setTimeout(() => {
				clearItemFromSessionStorage('flightParameterObj')
			}, 3000)
		} else {
			// setEnquiredPresent(false);
			setTimeout(() => {
				clearItemFromSessionStorage('flightParameterObj')
			}, 3000)
			return
		}
	}, [flightParameterObj, flightNumberValCombination, flightDateValCombination])


	const handleFlightSelect = (destinationAirport) => {
		let tempSearchObj
		if (
			searchFormData.isUSBound &&
			destinationAirport.usoutbound &&
			!searchFormData.isUnknownShipment
		) {
			tempSearchObj = getBase64Decoded(getFromSessionStorage('searchObject'))
			setSearchFormDataToState(tempSearchObj)
			setFlight(destinationAirport)
		} else if (destinationAirport.usoutbound && !
			(searchFormData.isUnknownShipment ||
				filters?.isUnknownShipment)) {
			const header = t('COMMON.CONFIRM')
			const message = t('BOOKING.CONFIRM_SHIPPER_KNOWN')
			const primaryBtnLbl = t('COMMON.YES')
			const acceptFn = () => {
				tempSearchObj = getBase64Decoded(getFromSessionStorage('searchObject'))
				setSearchFormDataToState(tempSearchObj)
				setFlight(destinationAirport)
			}
			const secondaryBtnLbl = t('COMMON.NO')
			const rejectFn = () => {
				setFiltersToState({
					...filters,
					isUnknownShipment: true,
				})
			}
			showInfoPopup(
				header,
				message,
				primaryBtnLbl,
				acceptFn,
				secondaryBtnLbl,
				rejectFn)
			//alert('The route contains US outbound assenger flights. Please confirm that the shipper is known.)
			//Yes
			//If yes, proceed to select flight

			//No
			//If no, close the dialog box and set the isUnknownShipment flag to true in searchformdata
		} else {
			tempSearchObj = getBase64Decoded(getFromSessionStorage('searchObject'))
			setSearchFormDataToState(tempSearchObj)
			setFlight(destinationAirport)
		}
	}

	return (
		<>
			<div className='flight-details__wrap'>
				<div
					className={`flight-details__item ${isExpanded ? 'borderB0 border-expand' : ''
						}`}
					onClick={() => setExpanded(!isExpanded)}
				>
					{destinationAirport.allotmentAvailable ?
						// <div className='flight-details__allotment-wrapper'>
						<div className='flight-details__allotment-indicator__flight-item' />
						// </div> 
						:
						<></>
					}
					<div className='flight-details__left'>
						<div className='logo d-none d-lg-block'>
							<img src={siteLogo} alt='Etihad' title='Etihad' />
						</div>

						<div className='flight-details__route'>
							{routes ? (
								<span className='flight-details__routes'>
									<span className='flight-details__routes-dep d-inline-block d-lg-none'>{departureTime.format('HH:mm')}</span>
									<span>{routes} {t('COMMON.STOP')}</span>
									<span className='flight-details__routes-arr d-inline-block d-lg-none'>{arrivalTime.format('HH:mm')}</span>
								</span>
							) : (
								<span className='flight-details__routes'>
									<span className='flight-details__routes-dep d-inline-block d-lg-none'>{departureTime.format('HH:mm')}</span>
									<span></span>
									<span className='flight-details__routes-arr d-inline-block d-lg-none'>{arrivalTime.format('HH:mm')}</span>
								</span>
							)}
							<ul className='flight-details__timing'>
								<li className='flight-details__departure'>
									<strong className='d-none d-lg-inline-block'>{departureTime.format('HH:mm')}</strong>
									<span>{departureDate.format('ddd DD MMM')}</span>
									<span>{origin}</span>
								</li>

								<div className='flight-details__connection'>
									<div className='origin-destination-icon dark'></div>
									{/* {
										destinationAirport.flightSegmentList.filter((val, ind) => ind < destinationAirport.flightSegmentList.length-1).map((ind) => {
											return <>
												<div className='transit-line'></div>									
												<div className='origin-destination-icon'></div>											
											</>
										})
									} */}
									<div className='transit-line'></div>
									<div className='iconsSection'>
										<i>
											{destinationAirport.flightSegmentList[0].flightType !=
												'T' ? (
												<img src={flightIcon} alt='Flight' title='Flight' />
											) : (
												<img src={truckIcon} alt='Flight' title='Flight' />
											)}
										</i>
									</div>
									<div className='transit-line' />
									<div className='origin-destination-icon dark'></div>
								</div>

								<li className='flight-details__arrival'>
									<strong className='d-none d-lg-inline-block'>{arrivalTime.format('HH:mm')}</strong>
									<span>{arrivalDate.format('ddd DD MMM')}</span>
									<span>{destination}</span>
								</li>
							</ul>
							<span className='flight-details__elapse'>
								{getFormatedTime(destinationAirport.cumulativeJourneyTime)}
							</span>
						</div>
					</div>
					<div className='flight-details__right'>
						<div className='flight-details__right__service'>
							{destinationAirport.service &&
								destinationAirport.service === SERVICES.FAST_TRACK ? (
								<img src={fastTrack} alt='fastTrack' title='fastTrack' />
							) : (
								<div></div>
							)}
						</div>
						<div className='flight-details__right__sub'>
							{destinationAirport.ratingDetails[0].totalRate &&
								destinationAirport.ratingDetails[0].totalRate !== 0 &&
								!isHideRate
								? (
									<div className='flight-details__right__sub__pricing'>
										<div className='flight-details__right__sub__amount'>
											<label>
												{destinationAirport.ratingDetails[0].currency}{' '}
												{getDecimalFormattedNumber(
													destinationAirport.ratingDetails[0].grandTotal
												)}
											</label>
										</div>
										<div className='flight-details__right__sub__rate'>
											<label>
												{destinationAirport.ratingDetails[0].currency}{' '}
												{getDecimalFormattedNumber(
													destinationAirport.ratingDetails[0].totalRate
												)}
												{'/'}
												{getWeightUnit(WEIGHT_UNIT.KILOGRAM)}
											</label>
										</div>
										{
											(destinationAirport.priceClass) ? (
												<div className='rate-type mb-0 d-none d-lg-block'>
													<span>{t(RATE_TYPES[destinationAirport.priceClass])}</span>
												</div>
											) : (<></>)
										}
										{(destinationAirport.overBookingAllowed ||
											destinationAirport.overHangShipment ||
											destinationAirport.bigShipment ||
											destinationAirport.isForceQueued) ? (
											<>
												<div className='d-none d-lg-block'>
													<div className='no-instant-booking mb-0'>
														<span>{t('CHOOSE_FLT.NO_INS_CONFIR')}</span>
													</div>
												</div>
												<div className='d-block d-lg-none'>
													{!isExpanded ? (
														<div className='no-instant-booking mb-0'>
															<span>{t('CHOOSE_FLT.NO_INS_CONFIR')}</span>
														</div>) : (<></>)
													}
												</div>
											</>
										) : (<></>)
										}
									</div>
								) : (
									<div className='flight-details__right__sub__pricing'>
										<div className='no-rates pr-3'>
											<span>{t('COMMON.NO_RATES')}</span>
										</div>
										<div className='d-none d-lg-block'>
											<div className='no-instant-booking mb-0'>
												<span>{t('CHOOSE_FLT.NO_INS_CONFIR')}</span>
											</div>
										</div>
										<div className='d-block d-lg-none'>
											{!isExpanded ? (
												<div className='no-instant-booking mb-0'>
													<span>{t('CHOOSE_FLT.NO_INS_CONFIR')}</span>
												</div>) : (<></>)}
										</div>
									</div>
								)}
							<button
								type='button'
								className='l-btn l-btn--primary d-none d-lg-block'
								onClick={() => handleFlightSelect(destinationAirport)}
							>
								{t('COMMON.CHOOSE')}
							</button>
							<div class='flight-details__arrowUp d-none d-lg-block'>
								<i>
									{isExpanded ? (
										<img src={minusIcon} alt='Flight' title='Flight' />
									) : (
										<img src={plusIcon} alt='Flight' title='Flight' />
									)}
								</i>
							</div>
							<div class='flight-details__arrowUp d-block d-lg-none'>
								<i>
									{isExpanded ? (
										<img src={upArrow} alt='Flight' title='Flight' />
									) : (
										<img src={downArrow} alt='Flight' title='Flight' />
									)}
								</i>
							</div>
						</div>
						{isExpanded ? (<></>) : (<button
							type='button'
							className='l-btn l-btn--primary d-block d-lg-none flight-details_choose'
							onClick={() => handleFlightSelect(destinationAirport)}
						>
							{t('COMMON.CHOOSE')}
						</button>)}

					</div>
				</div>
				{isExpanded ? (
					<div
						className={`flight-details__expanded col-md-12 p-l-0 p-r-0 ${isExpanded ? 'border-expand borderT0' : ''
							}`}
					>
						<div class='flight-details__expandedDetails'>
							<div className='flight-details__segment-info'>
								{destinationAirport.flightSegmentList.length ? (
									destinationAirport.flightSegmentList.map((segment, index) => {
										return (
											<>
												<div className='flight-details__routeDetails'>
													<div className='flight-details__flightNo'>
														<div>
															{segment.flightCarrierCode}
															{segment.flightNumber}
														</div>
														<div>{segment.aircraftType}{segment.aircraftClassification && <> | {segment.aircraftClassification}</>}</div>
													</div>
													<div className='flight-details__route'>
														<div className='flight-details__timing'>
															<div className='flight-details__departure'>
																<strong>
																	{moment(segment.departureDateTime).format(
																		'HH:mm'
																	)}
																</strong>
																<span>
																	{moment(segment.departureDateTime, 'DD-MMM-YYYY').format(
																		'ddd DD MMM'
																	)}
																</span>
																<span>{segment.segmentOrigin}</span>
																{/* <span class="flight-details__elapse">LAT : </span>*/}
															</div>
															<div className='flight-details__connection'>
																<div className='origin-destination-icon dark'></div>
																<div className='transit-line' />
																<div className='iconsSection'>
																	<i>
																		{segment.flightType != 'T' ? (
																			<img
																				src={flightIcon}
																				alt='Flight'
																				title='Flight'
																			/>
																		) : (
																			<img
																				src={truckIcon}
																				alt='Flight'
																				title='Flight'
																			/>
																		)}
																	</i>
																</div>
																<div className='transit-line' />
																<div className='origin-destination-icon dark'></div>
															</div>

															<div className='flight-details__arrival'>
																<strong>
																	{moment(segment.arrivalDateTime).format(
																		'HH:mm'
																	)}
																</strong>
																<span >
																	{moment(segment.arrivalDateTime, 'DD-MMM-YYYY').format(
																		'ddd DD MMM'
																	)}
																</span>
																<span>{segment.segmentDestination}</span>
															</div>
														</div>
														<span className='flight-details__elapse expanded'>
															{getFormatedTime(segment.flyingHours)}
														</span>
													</div>
												</div>
												{segment.segmentDestination != destination && (
													<div className='flight-details__layover'>
														<div className='segment-transit-line' />
														<span>
															{segment.layoverTime} Hrs in{' '}
															{segment.segmentDestination}
														</span>
														<div className='segment-transit-line' />
													</div>
												)}
											</>
										)
									})
								) : (
									<></>
								)}
							</div>
							<div className='flight-details__others d-flex col-lg-6 col-12'>
								{destinationAirport.flightSegmentList.length ? (
									destinationAirport.flightSegmentList.map((segment, index) => {
										return (
											index == 0 && (
												<>
													<div className='time-details'>
														<div className='other-value'>
															<div className='chargeable-weight'>
																LAT <span>({t('CHOOSE_FLT.LAT_FILTER')}) </span>
															</div>
															<div className='other-value'>
																<span className='time-field'>
																	{moment(
																		destinationAirport.lastAcceptanceTime
																	).format('HH:mm')} &nbsp;
																</span>
																{moment(
																	destinationAirport.lastAcceptanceTime, 'DD-MMM-YYYY'
																).format('ddd DD MMM')}{' '}
															</div>
														</div>
														<div className='other-value chargeable-weight_wrap'>
															<div className='chargeable-weight'>
																{t('COMMON.CH_WT')}
															</div>
															<div className='other-value'>
																{getRoundedValue(searchFormData?.chargeableWeight)}
																{getWeightUnit(WEIGHT_UNIT.KILOGRAM)}
															</div>
														</div>
													</div>
													<div className='weight-details'>
														<div className='other-value pb-1'>
															<div className='chargeable-weight'>
																TOA <span>({t('CHOOSE_FLT.TOA_FILTER')}) </span>
															</div>
															<div className='other-value pb-1'>
																<span className='time-field'>
																	{moment(
																		destinationAirport.earliestDeliveryTime
																	).format('HH:mm')} &nbsp;
																</span>
																{moment(
																	destinationAirport.earliestDeliveryTime, 'DD-MMM-YYYY'
																).format('ddd DD MMM')}{' '}
															</div>
														</div>
													</div>
													{destinationAirport.ratingDetails[0].totalRate &&
														destinationAirport.ratingDetails[0].totalRate !== 0 &&
														!isHideRate && isExpanded
														? (
															<>
																{(destinationAirport.overBookingAllowed ||
																	destinationAirport.overHangShipment ||
																	destinationAirport.bigShipment ||
																	destinationAirport.isForceQueued) ? (
																	<div className='mt-3 mb-3 d-block d-lg-none'>
																		<div className='no-instant-booking expanded mb-0'>
																			<img src={warning_filled} alt='warning_filled' title='warning_filled' />
																			<span>{t('CHOOSE_FLT.NO_INS_CONFIR')}</span>
																		</div>
																	</div>
																) : (<></>)
																}
															</>
														) : (
															<div className='mt-3 mb-3 d-block d-lg-none'>
																<div className='no-instant-booking expanded mb-0'>
																	<img src={warning_filled} alt='warning_filled' title='warning_filled' />
																	<span>{t('CHOOSE_FLT.NO_INS_CONFIR')}</span>
																</div>
															</div>
														)}
													<div className='mt-3 d-block d-lg-none'>
														{isExpanded ? (<button
															type='button'
															className='l-btn l-btn--primary flight-details_choose'
															onClick={() => handleFlightSelect(destinationAirport)}
														>
															{t('COMMON.CHOOSE')}
														</button>) : (<></>)}
													</div>
												</>
											)
										)
									})
								) : (
									<></>
								)}
							</div>
							{/*<div className='flight-details__right'>
							<div className='flight-details__info'>
								{destinationAirport.product &&
								destinationAirport.product === PRODUCTS.TEMP_CHECK ? (
									<img src={tempCheck} alt='tempCheck' title='tempCheck' />
								) : (
									<></>
								)}
								{destinationAirport.product &&
								destinationAirport.product === PRODUCTS.FAST_TRACK ? (
									<img src={fastTrack} alt='fastTrack' title='fastTrack' />
								) : (
									<></>
								)}
							</div>
						</div> */}
						</div>
					</div>
				) : (
					<></>
				)}
			</div>
		</>
	)
}

export default FlightDetails
